import React from "react";

import * as SocialStyles from './social.module.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTwitter,
    faInstagram,
    faItchIo,
} from "@fortawesome/free-brands-svg-icons"

import { useStaticQuery, graphql } from 'gatsby'

const Social = () => {
    const data = useStaticQuery(graphql`
  query{
      site{
          siteMetadata{
              twitterLink
              instagramLink
              itchLink
          }
      }
  }
`)

    return(
        <div>
            <div className={SocialStyles.sociallinks}>
                <a href={data.site.siteMetadata.instagramLink} target="_blank" rel="noreferrer" aria-label="Instagram link"><FontAwesomeIcon icon={faInstagram} className={SocialStyles.instagramstyle} size="2x"/></a>
                <a href={data.site.siteMetadata.twitterLink} target="_blank" rel="noreferrer" aria-label="Twitter link"><FontAwesomeIcon icon={faTwitter} className={SocialStyles.twitterstyle} size="2x"/></a>
                <a href={data.site.siteMetadata.itchLink} target="_blank" rel="noreferrer" aria-label="Itch.io link"><FontAwesomeIcon icon={faItchIo} className={SocialStyles.itchiostyle} size="2x"/></a>
            </div>
        </div>
    )
}

export default Social