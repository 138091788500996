import React from "react";

import { Link } from "gatsby";
import * as FooterStyles from './footer.module.scss'
import Social from "../components/social"

const Footer = () => {
    return(
        <div>
            <div class="dashed-line"></div>
            <footer className={FooterStyles.container}>
                <div className={FooterStyles.ownership}>
                    <h3>COPYRIGHT 2021 KingsCourtStudio</h3>
                    <p>|</p>
                    <Link to="/privacy">Privacy Policy</Link>
                    <p>|</p>
                    <Link to="/presskit">PressKit</Link>
                </div>
                <div className={FooterStyles.social}>
                    <Social/>
                </div>
            </footer>
        </div>
    )
}

export default Footer