import React from "react";

import Header from './header'
import Footer from './footer'

import '../styles/index.scss'

import * as LayoutStyles from './layout.module.scss'

const Layout = (props) => {
    return(
        <div className={LayoutStyles.container}>
            <Header/>
            {props.children}
            <Footer/>
        </div>
    )
}

export default Layout