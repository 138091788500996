import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from 'gatsby'

const Head = ({title, description, pathname, schemaMarkup, lang, meta}) => {

    const data = useStaticQuery(graphql`
        query{
            site{
                siteMetadata{
                    title
                    description
                    keywords
                    siteUrl
                    author
                }
            }
        }
    `)

    const metaDescription = description || data.site.siteMetadata.description
    const canonical = pathname ? `${data.site.siteMetadata.siteUrl}${pathname}` : null

  return(
        <Helmet 
            htmlAttributes={{
                lang,
            }} 
            title={`${title} | ${data.site.siteMetadata.title}`}
            link={
                canonical
                  ? [
                      {
                        rel: "canonical",
                        href: canonical,
                      },
                    ]
                  : []
            }
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    name: "keywords",
                    content: data.site.siteMetadata.keywords.join(","),
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:creator`,
                    content: data.site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ].concat(meta)}
        >
            {schemaMarkup &&
                <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
            }
        </Helmet>
  )
}

Head.defaultProps = {
    lang: `en`,
    meta: [],
    description: '',
}

Head.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    pathname: PropTypes.string,
}

export default Head