import React from "react";
import { Link } from "gatsby";

import * as HeaderStyles from './header.module.scss'
import { StaticImage } from "gatsby-plugin-image"

const Header = () => {
    return(
        <div className={HeaderStyles.banner}>
            <div className={HeaderStyles.header}>
                <Link to="/">
                <div className={HeaderStyles.companylogo}>
                    <StaticImage src="../media/images/TWWWLogo.png" alt="The War Will Win Logo"/>
                    <h1>The War Will Win</h1>
                </div>
                </Link>
            </div>
            <nav className={HeaderStyles.navigation}>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/#Trailer-Section">Trailer</Link></li>
                <li><Link to="/#About-Section">About</Link></li>
                <li><Link to="/presskit">PressKit</Link></li>
                <li><Link to="/#Contact-Section" >Contact</Link></li>
            </nav>
            <div class="dashed-line"></div>
        </div>
    )
}

export default Header